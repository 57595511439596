"use client";

import NextError from "next/error";
import { useEffect } from "react";
import * as Sentry from "@sentry/nextjs";

export default function GlobalError({ error }: { error: Error & { digest?: string } }) {
    useEffect(() => {
        Sentry.captureException(error);
    }, [error]);

    return (
        // global-error must include html and body tags
        <html>
            <body>
                <NextError statusCode={0} title={"Internal Server Error"} />
            </body>
        </html>
    );
}
